// Styles
require('jquery-ui/themes/base/all.css');

// Core
require('jquery-ui/ui/core');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/position')

// Interactions
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/droppable');

// Widgets
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/accordion');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/ui/widgets/tabs');
require('jquery-ui/ui/widgets/tooltip');

// Effects
require('jquery-ui/ui/effects/effect-highlight');
require('jquery-ui/ui/effects/effect-pulsate');
