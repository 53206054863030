require('jquery');
require('legacy/jquery-migrate-1.2.1.min');
require('jquery-ujs');
require('legacy/_jquery-ui-application');
require('legacy/jquery.spinner');
require('legacy/jquery.timer');
require('legacy/answering-process');
require('legacy/overlay_remote_data_loader');
require('legacy/jquery.ba-throttle-debounce');
require('legacy/_browser_detection');
require('legacy/workarounds/_fix_wrapper_height');
require('legacy/jquery.ui.touch.punch');
require('legacy/messages/plugin');

$(function() {
  function resizeChangeLanguageOverlay() {
    var change_language_overlay = $('#overlay_switch_questionnaire_language'),
        window_height = $(window).height();

    // Resize language overlay to fit the window
    $('.overlay-data', change_language_overlay).height(window_height - 130);
  }

  function fixIEcontentHeight() {
    if (isBrowser.isIE(8)) {
      $('#content').height( $('body').height() );
    }
  }

  resizeChangeLanguageOverlay();
  fixIEcontentHeight();

  $(window).resize($.throttle(200, function() {
    resizeChangeLanguageOverlay();
    fixIEcontentHeight();
  }));
});
