window.Application = window.Application || {};

Application.fixWrapperHeight = function() {
  function updateWrapperHeight() {
    $('.wrapper').css('height', Math.round($(window).height()));
  }

  updateWrapperHeight();

  $(window).resize($.throttle(700, updateWrapperHeight));
};

$(function() {
  if (isBrowser.SafariMobile()) {
    Application.fixWrapperHeight();
  }
});
