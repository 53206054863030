isBrowser = {
  Safari: function() {
    return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  },
  SafariMobile: function() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) != null;
  },
  IE: function() {
    return $.browser.msie == true;
  },
  isIE: function(version) {
    if ($.isNumeric(version)) {
      return $.browser.msie == true && parseInt($.browser.version, 10) == version;
    } else if (!version || !$.isNumeric(version)) {
      return $.browser.msie == true && parseInt($.browser.version, 10);
    } else {
      return false;
    }
  }
}
