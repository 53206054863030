require('legacy/vendor/vendor');
require('legacy/json2');
require('legacy/answering_process/reasoning_answering_browser_behavior');
require('legacy/answering_process/time_counter');
require('legacy/answering_process/values_time_counter');
require('legacy/answering_process/questionnaire');
require('legacy/answering_process/overlay_link');
require('legacy/answering_process/form');
require('legacy/answering_process/open_form');
require('legacy/answering_process/legend');
require('legacy/answering_process/open_legend');
require('legacy/answering_process/open_questionnaire');
require('legacy/answering_process/dpa_questionnaire');
require('legacy/answering_process/values_questionnaire');
require('legacy/answering_process/reasoning_questionnaire');
require('legacy/answering_process/countdown');

$ ->
  reasoningNotDuringTest = ->
    $('#questionnaires-reasoning').size() && !$('#questionnaires-reasoning .inner-content').attr('id')

  if $('#questionnaires-dpa').size() || $('#questionnaires-answerings').size() || $('#questionnaires-rm').size()
    new DpaQuestionnaire
  if $('#questionnaires-open').size()
    new OpenQuestionnaire
  if $('.values-questionnaire').size()
    new ValuesQuestionnaire
  if reasoningNotDuringTest()
    new ReasoningQuestionnaire
